import React from 'react'

import GlobalStyles from './GlobalStyles'

import Home from './pages/Home'

function App() {
  return (
    <>
      <GlobalStyles/>
      <Home/>
    </>
  )
}

export default App
